.my-bill-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
}

.view-bill-popover-action-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  button {
    width: 100%;
    text-align: left;
    &:last-child {
      border-bottom: none;
    }
  }
}
