@media only screen and (max-width: 600px) {
  .bill-container {
    padding: 15px 0;
  }

  .clear-list {
    padding: 0 16px;
  }

  .my-bill-container .top-5-people {
    flex-direction: column;
    .person-card {
      margin-bottom: 15px;
      width: initial;
      display: flex;
      justify-content: space-between;
      .person-name {
        margin-bottom: 0;
      }
    }
  }

  .member-section {
    padding: 15px;
  }
  .form-add-list,
  .form-add-people,
  .add-bill-name,
  .qr-code-section {
    padding: 0 16px;
  }
  div.member-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
