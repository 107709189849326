.bill-container {
  max-width: 667px;
  margin: 0 auto;
  padding: 15px;
  position: relative;
  &.policy-page {
    font-family: Sarabun-Regular;
    h1 {
      font-size: 22px;
      margin-bottom: 0;
    }
  }
}

.wrap-notification-message {
  font-family: Sarabun-Regular;
  ul {
    padding-left: 0;
  }
}

.bill-container {
  background: #fff;
  height: auto;
  position: relative;
  border-radius: 3px;
}

.cover-ribbon {
  height: 115px;
  width: 115px;
  position: absolute;
  right: -8px;
  top: -8px;
  overflow: hidden;
}
.cover-ribbon .cover-ribbon-inside {
  background: #1da57a;
  color: #fff;
  transform: rotate(45deg);
  position: absolute;
  right: -35px;
  top: 15px;
  padding: 10px;
  min-width: 127px;
  text-align: center;
}
.cover-ribbon .cover-ribbon-inside:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #177759;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  top: 35px;
  transform: rotate(-45deg);
}
.cover-ribbon .cover-ribbon-inside:after {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 10px solid #177759;
  border-bottom: 7px solid transparent;
  position: absolute;
  left: 95%;
  right: 0;
  top: 34px;
  bottom: 0;
  content: "";
  transform: rotate(-45deg);
}

.qr-code-section {
  margin: 15px 0;
}

.bill-action {
  button {
    margin: 5px;
  }
}

.qr-code-container {
  display: inline-block;
}

.modal-add-list {
  .ant-modal-header {
    text-align: center;
    .ant-modal-title {
      span {
        &:last-child {
          font-size: 25px;
        }
      }
    }
  }
}

.detail-section {
  text-align: center;
  h2,
  h3 {
    margin: 0;
  }
}

.bill-detail {
  margin: 20px 0;
}

.hide-for-capture {
  display: none !important;
}

.custom-tabs {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    flex: 1;
    max-width: 50% !important;
    display: flex;
    justify-content: center;
  }

  .ant-tabs-nav-wrap {
    overflow: initial;
  }
  .ant-tabs-nav-more {
    display: none;
  }
}

.bill-name {
  font-family: Sarabun-Medium;
}

.input-item-name {
  input {
    font-size: 25px;
    outline: none;
    text-align: center;
    margin-top: 32px;
  }
}

.clear-list {
  margin-bottom: 16px;
}

.table-peoples {
  tr {
    td,
    th.ant-table-cell {
      text-align: center;
      &:first-child,
      &:nth-child(2) {
        text-align: left;
      }
    }
  }
}

.tab-list-content {
  position: relative;
  > button {
    position: absolute;
    z-index: 1;
    left: 30%;
    top: 30%;
  }
  thead th {
    &:last-child {
      text-align: center;
    }
  }
}

.screenshot-btn {
  border-radius: 15px;
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-family: Sarabun-Medium;
  transition: background-color 0.3s;
  background-color: #0061df;
  box-shadow: 0 2px 0 rgba(5, 155, 255, 0.1);
  width: 80%;
}

.warning-text-capture {
  font-size: 12px;
  color: #f5a623;
  font-style: italic;
}

.screenshot-btn:hover {
  background-color: #0056b3;
}

.blurred-container {
  filter: blur(4px);
  pointer-events: none;
}

.member-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.hide-element {
    display: none;
  }

  .wrap-powerby {
    cursor: pointer;
    div {
      font-family: Sarabun-Medium;
      font-size: 17px;
      font-style: italic;
    }
  }
  .dropdown-usermenu {
    margin-left: auto;
  }
  .language-switcher {
    padding: 0;
  }
}

.modal-login {
  .wrap-signin-google {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  button.google-signin-btn {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 30px;
      height: auto;
    }
  }
}

.dropdown-usermenu {
  ul {
    li span button {
      width: 100%;
    }
  }
}

.text-browser-warning {
  margin-top: 12px;
  text-align: center;
  span {
    font-style: italic;
    color: #000000;
  }
}

.user-button-icon {
  margin-left: auto;
  display: flex;
  height: 40px;
  align-items: center;
  border: none;
  box-shadow: none;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.permission-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  flex-direction: column;
}

.language-switcher {
  border: none;
  box-shadow: none;
}

.my-bill-container.admin-jsor {
  max-width: 95%;
  .admin-dashboard-table {
    margin-top: 25px;
  }
}

.my-bill-container {
  .top-5-people {
    display: flex;
    margin-bottom: 25px;
  }

  .range-picker-container {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .person-card {
    width: 100%;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    margin: 0 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .person-name {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .person-total {
      color: #ff4500;
    }
  }
}

.wrap-date-range-picker {
  margin-top: 15px;
}

.promtpay-detail-and-copy {
  > div {
    margin-right: 10px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.modal-what-people-order {
  .ant-modal-content {
    padding: 0 0 15px 0;
  }
}
